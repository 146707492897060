
import { useStore } from 'vuex';
import { defineComponent, ref,onMounted,computed} from 'vue'
import { useRouter } from 'vue-router';


export default defineComponent({
  components: {
   
  },
  setup(){
    const store = useStore();
    const router = useRouter();

    const fields = ref([
      {
        title: "Arnoc",
        dataIndex: "arnoc",
      },
      {
        title: "Ship To",
        dataIndex: "ship_to",
      },
      {
        title: "Site Name",
        dataIndex: "site_name",
      },
      {
        title: "Retailer",
        dataIndex: "retailer.full_name",
      },
      {
        title: "Type",
        dataIndex: "type",
      },
      {
        title: "Cluster",
        dataIndex: "cluster.cluster_name",

      },
      {
        title: "Zone",
        dataIndex: "zone.name",

      },
      {
        title: "Territory Manager",
        dataIndex: "territory_manager.full_name",

      },
      {
        title: "District",
        dataIndex: "zone.district.district_name",

      },
      {
        title: "District Manager",
        dataIndex: "zone.district.district_manager.full_name",

      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      },
    ]);

    const index = (payload={page: 1}) => {
      store.dispatch("RetailerSites/index", payload);
    };

    const filterRecord = (payload: any) => {
      index(payload);
    };

    const editRecord = (record:any) => {
      router.push(`/retailer-site-details-form/`+record.id)
    };

    const items = computed(() => store.getters["RetailerSites/items"]);
    const loading = computed(() => store.getters["RetailerSites/status"]);

    onMounted(index);


    return {
      loading,
      items,
      fields,
      filterRecord,
      editRecord
    };
  },
});
